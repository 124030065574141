import React from 'react';
import { Element } from 'react-scroll';
import { Hbox } from '@atomic/obj.box';
import { FormContext } from '../form.context';
import { Field, FormFlowFieldsContainer, Step } from './form-flow-fields.container';
import { FormSummary } from './formSummary';
import { DetailsWrapper } from './result/form-result.component.style';

interface FormFields {
  label?: string;
  typeField: string;
  question: string;
  detail?: string;
  placeholder?: string;
  key: string;
  mandatory?: boolean;
  display: boolean;
  conditional?: {
    key: string;
    conditionalValue: string;
  };
  options?: [
    {
      label: string;
      key: string;
    },
  ];
}
interface FormFlowStep {
  key: string;
  label: string;
  question: string;
  fields: FormFields[];
}
export interface FormFlowProps {
  formFlowData: { steps: FormFlowStep[] };
}

interface FormSectionProps {
  step: { key: string; questions: Step };
  stepIndex: number;
  //loadings: boolean;
}

export const FormSection = (props: FormSectionProps) => {
  const {
    formResult,
    setFormResult,
    //clearNluAttributes,
    disableSubmit,
    setConversationId,
    setDisableSubmit,
    userInfo,
    strings,
    setInitialized,
    show,
    postFile,
    callFlows,
    callBackAttribute,
    openModals,
    showLoading,
  } = React.useContext(FormContext);

  const handleValue = (
    value: string,
    typeField: string,
    keyField?: string,
    conditional?: any,
    stepKey?: string,
    unit?: string,
  ) => {
    setInitialized(true);
    let selectedValue;
    if (typeField === 'radio') {
      [selectedValue] = formResult[stepKey].questions.fields[keyField].options.filter(op => op.key === value);
    } else {
      selectedValue = value;
    }

    console.log('handlevalue', value, keyField, selectedValue);

    if (keyField === 'CHECK_FILE' && disableSubmit) {
      setDisableSubmit(false);
    }

    if (keyField === 'FLOW') {
      callFlows(selectedValue.value);
      showLoading('STEEL_NAME', 'STEEL');
    } else if (keyField === 'STEEL_NAME') {
      const payload = { steel: selectedValue.label, field: 'STEEL_NAME' };
      const url = formResult[stepKey].questions.fields[keyField].callback;
      callBackAttribute(payload, url);
      showLoading('SPECIFIC_SPECIFICATION', 'STEEL');
    } else if (keyField === 'SPECIFIC_SPECIFICATION') {
      openModals('SPECIFIC_SPECIFICATION');
    }

    // else if (v) {
    //   const payload = { steel: value.label };
    //   const url = formResult[stepKey].questions.fields[keyField].callback;

    //   callBackAttribute(payload, url);
    // }

    // if (
    //   formResult?.CLIENT_CODE?.value &&
    //   formResult?.STANDARD?.value &&
    //   keyField === 'CHECK_FILE' &&
    //   value?.key === 'NO'
    // ) {
    //   setConversationId(null);
    //   clearNluAttributes();
    //   const conditionalField = props.formFlowData.steps[stepIndex].fields.find(field => field.key === 'STEEL_NAME');
    //   conditionalField.display = false;
    // }

    if (formResult) {
      setFormResult(prevValue => ({
        ...prevValue,
        [stepKey]: {
          ...prevValue[stepKey],
          questions: {
            ...prevValue[stepKey]?.questions,
            fields: {
              ...prevValue[stepKey]?.questions.fields,
              [keyField]: {
                ...prevValue[stepKey]?.questions.fields[keyField],
                value: selectedValue,
                readBy: 'user',
              },
            },
          },
        },
      }));

      if (unit && unit !== undefined) {
        setFormResult(prevValue => ({
          ...prevValue,
          [stepKey]: {
            ...prevValue[stepKey],
            questions: {
              ...prevValue[stepKey]?.questions,
              fields: {
                ...prevValue[stepKey]?.questions.fields,
                [keyField]: {
                  ...prevValue[stepKey]?.questions.fields[keyField],
                  unit: {
                    ...prevValue[stepKey]?.questions.fields[keyField].unit,
                    symbol: unit,
                  },
                },
              },
            },
          },
        }));
      }
    }

    if (conditional) {
      const fieldsWithConditional = Object.values(formResult[stepKey].questions.fields).filter(
        (field: Field) => field?.conditional?.length > 0,
      );

      fieldsWithConditional.forEach((field: Field) => {
        setFormResult(prevValue => ({
          ...prevValue,
          [stepKey]: {
            ...prevValue[stepKey],
            questions: {
              ...prevValue[stepKey]?.questions,
              fields: {
                ...prevValue[stepKey]?.questions.fields,
                [field.key]: {
                  ...prevValue[stepKey]?.questions.fields[field.key],
                  value: null,
                  display: false,
                },
              },
            },
          },
        }));

        //console.log('vvvv', field);
        const keys = [];
        const values = [];
        field.conditional.forEach(op => {
          keys.push(op.key);
          values.push(op.value);
        });

        const options = [];
        if (field?.options) {
          field.options.forEach(op => {
            if (op.conditional.length > 0) {
              op.conditional.forEach(op2 => {
                options.push(op2.value);
              });
            } else {
              options.push(op.value);
            }
          });
        }

        if (options.includes(value)) {
          setFormResult(prevValue => ({
            ...prevValue,
            [stepKey]: {
              ...prevValue[stepKey],
              questions: {
                ...prevValue[stepKey]?.questions,
                fields: {
                  ...prevValue[stepKey]?.questions.fields,
                  [field.key]: {
                    ...prevValue[stepKey]?.questions.fields[field.key],
                    display: true,
                  },
                },
              },
            },
          }));
        }
      });
    }
  };

  const handleUpload = (file: File[], label?: string, stepKey?: string) => {
    if (!formResult.CLIENTS?.fields?.CLIENT_CODE?.value || !formResult[stepKey]?.fields?.STANDARD?.value) {
      show('alert', strings.newForm.messages.uploadAlert);
    } else {
      setFormResult(prevValue => ({
        ...prevValue,
        CLIENTS: {
          ...prevValue.CLIENTS,
          fields: {
            CLIENT: { ...prevValue.CLIENTS.fields.CLIENT },
            CLIENT_CODE: { ...prevValue.CLIENTS.fields.CLIENT_CODE },
            CORPORATE_CODE: { ...prevValue.CLIENTS.fields.CORPORATE_CODE },
            WHEN_CLIENT_DATA: { ...prevValue.CLIENTS.fields.WHEN_CLIENT_DATA },
          },
        },
        [stepKey]: {
          ...prevValue[stepKey],
          fields: {
            STANDARD: { ...prevValue[stepKey].fields.STANDARD },
            CHECK_FILE: { ...prevValue[stepKey].fields.CHECK_FILE },
            SPECIFIC_SPECIFICATION_NAME: {
              ...prevValue[stepKey].fields.SPECIFIC_SPECIFICATION_NAME,
              file: file,
              label,
            },
          },
        },
      }));
      setConversationId(null);

      postFile({
        client: {
          code: formResult.CLIENTS.fields.CLIENT_CODE.value,
          name: formResult.CLIENTS.fields.CLIENT.value,
          corporateCode: formResult.CLIENTS.fields.CORPORATE_CODE.value,
        },
        standard: {
          type: formResult[stepKey].fields.STANDARD.value.key,
        },
        file: file[0],
        language: userInfo.language,
      });
    }
  };

  console.log(formResult);

  return (
    <Element name={props.step?.questions?.key} key={props.step?.questions?.key}>
      <DetailsWrapper id={props.step?.questions?.key} bg={true}>
        <FormSummary
          stepIndex={props.stepIndex}
          stepKey={props.step?.questions?.key}
          stepLabel={props.step?.questions?.label}
          stepQuestion={props.step?.questions?.question}
          steps={props.step?.questions?.fields && Object.values(props.step.questions?.fields)}
        />
        <Hbox.Item>
          {props.step?.questions?.fields &&
            Object.entries(props.step.questions?.fields).map((field: [string, Field], index: number) => (
              <FormFlowFieldsContainer
                key={`${field[1].key}-${index}`}
                stepKey={props.step?.questions.key}
                field={field[1]}
                handleUpload={handleUpload}
                handleValue={handleValue}
              />
            ))}
        </Hbox.Item>
      </DetailsWrapper>
    </Element>
  );
};
