/* eslint-disable complexity */

import React from 'react';
import { Element } from 'react-scroll';
import { FormContext } from '../form.context';
import { AutoCompleteField } from './fields/autoCompleteField';
import { CheckBoxField } from './fields/checkboxField';
import { DateField } from './fields/dateField';
import { FileField } from './fields/fileField';
import { RadioField } from './fields/radioField';
import { RangeField } from './fields/rangeField';
import { SelectDefaultField } from './fields/selectField';
import { TableField } from './fields/tableField';
import { TextDefaultField } from './fields/textField';
import { FormFlowFieldsProvider } from './form-flow-fields.context';
import { FieldWrapper, MainQuestion, QuestionWrapper, SecondaryQuestion } from './result/form-result.component.style';

export interface Field {
  question: string;
  type: string;
  key: string;
  step: string;
  readBy: string;
  display: boolean;
  placeholder?: string;
  permissions: {
    visible: boolean;
    editable: boolean;
  };
  label?: string;
  options?: [
    {
      key: string;
      label: string;
      value: string;
      conditional?: [
        {
          key: string;
          value: string;
        },
      ];
    },
  ];
  isConditional?: boolean;
  conditional?: [
    {
      key: string;
      value: string;
    },
  ];
  mandatory?: boolean;
  detail?: string;
  value?: any;
  min?: number;
  max?: number;
  unit?: {
    symbol: string;
    description: string;
    allowed?: [
      {
        symbol: string;
        description: string;
      },
    ];
  };
  order: number;
  callback?: string;
  loading?: boolean;
}

export interface Steps {
  key: string;
  label: string;
  question: string;
  fields: Field[];
}

export interface Step {
  fields: Field;
  key: string;
  label?: string;
  question?: string;
}
export interface FormFlowProps {
  field?: Field;
  stepKey?: string;
  handleValue?: (
    value: any,
    typeField: string,
    keyField?: string,
    conditional?: boolean,
    stepKey?: string,
    unit?: string,
  ) => void;
  handleUpload?: (file: File[], label?: string, stepKey?: string) => void;
}

export const FormFlowFieldsContainer = (props: FormFlowProps) => {
  const { formResult } = React.useContext(FormContext);
  const [displayConditional, setDisplayConditional] = React.useState(false);

  // React.useEffect(() => {
  //   if (props?.field?.key === 'STEEL_NAME' && formResult?.CLIENT.questions?.fields?.CORPORATE_CODE?.value) {
  //     const payload = { corporateGroup: formResult.CLIENT.questions.fields.CORPORATE_CODE.value, field: props.field };
  //     const url = props.field.callback;

  //     callBackAttribute({ payload, url });
  //   }

  //   // } else if (props?.field?.key === 'CLIENT') {
  //   //   console.log('aquiii', props.field);
  //   //   const payload = { field: props.field };
  //   //   const url = props.field.callback;
  //   //   callBackAttribute({ payload, url });
  //   // }
  // }, [formResult?.CLIENT?.questions?.fields?.CORPORATE_CODE?.value]);

  // React.useEffect(() => {
  //   if (props?.field?.key === 'STEEL_NAME') {
  //     setFormResult(prevValue => ({
  //       ...prevValue,
  //       [props.stepKey]: {
  //         ...prevValue[props.stepKey],
  //         questions: {
  //           ...prevValue[props.stepKey].questions,
  //           fields: {
  //             ...prevValue[props.stepKey].questions.fields,
  //             [props.field.key]: {
  //               ...prevValue[props.stepKey].questions.fields[props.field.key],
  //               loading: loadingCallback,
  //             },
  //           },
  //         },
  //       },
  //     }));
  //   }
  // }, [loadingCallback]);

  React.useEffect(() => {
    setDisplayConditional(false);
    if (props?.field?.conditional?.length > 0) {
      props.field.conditional.forEach((op: { key: string; value: string }) => {
        if (formResult[props.stepKey]?.questions?.fields[op.key]?.value?.value === op.value) {
          setDisplayConditional(true);
        }
      });
    }
  }, [formResult]);

  return (
    (props.field.display || displayConditional) && (
      <Element name={props.field.key}>
        <QuestionWrapper id={props.field.key} data-testid={`field${props.field.key}`}>
          <MainQuestion>{props.field.question}</MainQuestion>
          {props.field.detail && <SecondaryQuestion>{props.field.detail}</SecondaryQuestion>}
          <FieldWrapper></FieldWrapper>

          <FieldWrapper>
            {props.field?.type === 'autocomplete' ? (
              <AutoCompleteField field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'radio' ? (
              <RadioField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'checkbox' ? (
              <CheckBoxField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'file' ? (
              <FileField handleUpload={props.handleUpload} field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'date' ? (
              <DateField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'table' ? (
              <TableField field={props.field} />
            ) : props.field?.type === 'select' ? (
              <SelectDefaultField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            ) : props.field?.type === 'range' ? (
              <RangeField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            ) : (
              <TextDefaultField handleValue={props.handleValue} field={props.field} stepKey={props.stepKey} />
            )}
          </FieldWrapper>
        </QuestionWrapper>
      </Element>
    )
  );
};

export const FormFlowFields = () => {
  return (
    <FormFlowFieldsProvider>
      <FormFlowFieldsContainer />
    </FormFlowFieldsProvider>
  );
};
