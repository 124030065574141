import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { Divisor } from '@atomic/atm.divisor';
import { Body, H1 } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ListNormWrapper } from '../components/result/form-result.component.style';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';
interface ModalNormAttributesProps {
  open: boolean;
  submitModals: (stepKey: string, key: string, values: any) => void;
}

export const ModalNormAttributes: React.FC<ModalNormAttributesProps> = props => {
  const { setModalToOpen, formResult, normInfos } = React.useContext(FormContext);
  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language].modalNormAttributes;
  const [opened, setOpened] = React.useState(props.open);

  console.log('normInfos', Object.entries(normInfos));

  const handleSubmit = action => {
    if (action === 'YES') {
      Object.entries(normInfos).forEach((item: [string, { key: string; label: string; value: string }]) => {
        const [key, object] = item;
        if (key === 'SPECIFIC_SPECIFICATION') {
          console.log(key, object);
          formResult.STEEL.questions.fields[key].value = object;
          formResult.STEEL.questions.fields[key].display = true;
        } else {
          console.log(key, object);
          formResult.PRODUCT.questions.fields[key].value = object;
          formResult.PRODUCT.questions.fields[key].display = true;
        }
      });
    } else {
      formResult.STEEL.questions.fields.SPECIFIC_SPECIFICATION.value = null;
    }

    setModalToOpen(null);
    setOpened(false);
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  return (
    <Modal preventOverlayClick small opened={opened} onClose={() => handleClose()}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <H1>{strings.title}</H1>
            <VSeparator />
            <Body>{strings.intro.replace('[ACO]', formResult.STEEL.questions.fields.STEEL_NAME.value.label)}</Body>
            <Body>
              <ListNormWrapper>
                {Object.entries(normInfos).map((item: [string, { key: string; label: string; value: string }]) => (
                  <li key={item[1].key}>{item[1].label}</li>
                ))}
              </ListNormWrapper>
            </Body>
            <Body>{strings.question}</Body>
          </Col>
        </Row>
        <Separator />
        <Separator />
        <Divisor />
        <VSeparator />
        <Hbox hAlign='flex-end'>
          <Hbox.Item noGrow>
            <Button kind='secondary' expanded onClick={() => handleSubmit('NO')}>
              {strings.btnCancel}
            </Button>
          </Hbox.Item>
          <Hbox.Separator />
          <Hbox.Item noGrow>
            <Button kind='primary' expanded onClick={() => handleSubmit('YES')}>
              {strings.btnSubmit}
            </Button>
          </Hbox.Item>
        </Hbox>
        <VSeparator />
      </Grid>
    </Modal>
  );
};
