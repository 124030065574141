/* eslint-disable complexity */

import { format } from 'date-fns';
import React from 'react';
import { DatePicker } from '@atomic/atm.date-picker';
import { Form, Validators } from '@atomic/obj.form';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';
import { FormResultItems } from '../result/form-result.component';

export const DateField = (props: FormFlowProps) => {
  const { formResult, strings, userInfo } = React.useContext(FormContext);
  const stringsNewForm = strings.newForm.messages;

  const checkValidator = (value: FormResultItems[], mandatory: boolean) => {
    if (mandatory) {
      if (value) {
        return [];
      }
      return [Validators.Required(stringsNewForm.mandatoryAlert)];
    } else {
      return [];
    }
  };

  return (
    <Form.Field name={props.field.key} validators={checkValidator(props.field?.value, props.field?.mandatory)}>
      <DatePicker
        onValueChange={value =>
          props.handleValue(
            value !== null && value !== '' ? format(value, 'yyyy-MM-dd HH:ii') : value,
            'date',
            props.field.key,
            false,
            props.stepKey,
          )
        }
        language={userInfo.language}
        startDate={formResult && props.field?.value !== null ? new Date(props.field?.value) : null}
        maxDate={props.field.key === 'WHEN_CLIENT_DATA ' ? new Date() : null}
      />
    </Form.Field>
  );
};
