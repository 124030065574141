import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { H1, H3 } from '@atomic/atm.typography';
import { Table, TD, TR } from '@atomic/mol.table';
import { Hbox } from '@atomic/obj.box';
import { Form, FormData, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';
import { ModalGenericValue } from './modal-generic-value.component';

export interface MicroinclusaoData {
  A: { FINO: number; GROSSO: number };
  B: { FINO: number; GROSSO: number };
  C: { FINO: number; GROSSO: number };
  D: { FINO: number; GROSSO: number };
}
export interface CustomData {
  label: string | number;
  values: string | number;
}
interface MicroinclusaoRowProps {
  label: string;
  data: { FINO?: number; GROSSO?: number };
}

const convertDataToNumber = (val: number) => {
  if (val !== undefined) {
    return +val.toString().replace(',', '.');
  }
  return +val;
};

const MicroinclusaoRow: React.FC<MicroinclusaoRowProps> = props => {
  return (
    <TR>
      <TD>
        <H3>{props.label}</H3>
      </TD>
      <TD>
        <Form.Field
          name={`${props.label}[FINO]`}
          initialValue={convertDataToNumber(props?.data?.FINO)}
          validators={[Validators.Required('Obrigatório')]}
          hideErrorCaption
        >
          <TextField placeholder='Fina' type='text' noArrows={true} />
        </Form.Field>
      </TD>
      <TD> - </TD>
      <TD>
        <Form.Field
          name={`${props.label}[GROSSO]`}
          initialValue={convertDataToNumber(props?.data?.GROSSO)}
          validators={[Validators.Required('Obrigatório')]}
          hideErrorCaption
        >
          <TextField placeholder='Grossa' type='text' noArrows={true} />
        </Form.Field>
      </TD>
    </TR>
  );
};
interface ModalMicroinclusaoProps {
  data: ElementProps[];
  open: boolean;
  submitModals: (stepKey: string, key: string, values: any) => void;
}
interface ElementProps {
  element: string;
  value?: {
    FINO?: number;
    GROSSO?: number;
  };
}

export const ModalMicroinclusao: React.FC<ModalMicroinclusaoProps> = props => {
  const { setModalToOpen } = React.useContext(FormContext);
  const [reset, setReset] = React.useState(0);
  const [opened, setOpened] = React.useState(props.open);
  const [newAttributeForm, setNewAttributeForm] = React.useState(false);
  const [initialElements, setInitialElements] = React.useState<ElementProps[]>([
    { element: 'A', value: { FINO: 0, GROSSO: 0 } },
    { element: 'B', value: { FINO: 0, GROSSO: 0 } },
    { element: 'C', value: { FINO: 0, GROSSO: 0 } },
    { element: 'D', value: { FINO: 0, GROSSO: 0 } },
  ]);

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language];

  React.useEffect(() => {
    if (props?.data) {
      setInitialElements(props.data);
    }
  }, [props.data]);

  const handleSubmit = (data: FormData<MicroinclusaoData>) => {
    if (Object.keys(data.error).length > 0) {
      return;
    }

    const newValues = Object.entries(data.data).map((item: [string, { FINO: number; GROSSO: number }]) => {
      return {
        element: item[0],
        value: { FINO: convertDataToNumber(item[1].FINO), GROSSO: convertDataToNumber(item[1].GROSSO) },
      };
    });
    newValues.shift();

    props.submitModals('ADDITIONAL', 'MICRO_INCLUSION', {
      value: newValues,
    });

    setModalToOpen(null);
  };

  const handleReset = () => {
    setReset(reset + 1);
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  const onSelectChange = value => {
    if (value === 'Outros') {
      setNewAttributeForm(true);
    } else {
      setNewAttributeForm(false);
    }
  };

  return (
    <Modal preventOverlayClick opened={opened} small onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        <Grid fluid>
          <Row mb>
            <Col xs={12}>
              <H1>{!newAttributeForm ? strings.modalMicroinclusao.title : strings.modalGenericValues.title}</H1>
            </Col>
            <Col xs={4}>
              <Form.Field
                name={'filter'}
                initialValue={strings.modalMicroinclusao.selectOptions[0]}
                hideErrorCaption
                onValueChange={e => onSelectChange(e)}
              >
                <SelectField>
                  {strings.modalMicroinclusao.selectOptions.map((el: string) => (
                    <option value={el} key={el}>
                      {el}
                    </option>
                  ))}
                </SelectField>
              </Form.Field>
            </Col>
          </Row>
          {!newAttributeForm ? (
            <Row mb key={reset}>
              <Col xs={12}>
                <Table>
                  {initialElements.map((item: ElementProps) => (
                    <MicroinclusaoRow label={item.element} key={item.element + reset} data={item?.value} />
                  ))}
                </Table>
              </Col>
            </Row>
          ) : (
            <ModalGenericValue type={strings.modalMicroinclusao.title} />
          )}
          <Hbox hAlign='flex-end'>
            <Hbox.Item noGrow>
              <Button kind='secondary' expanded onClick={handleReset}>
                {strings.modalMicroinclusao.btnReset}
              </Button>
            </Hbox.Item>
            <Hbox.Separator />
            <Hbox.Item noGrow>
              <Button kind='primary' expanded type='submit'>
                {strings.modalMicroinclusao.btnSubmit}
              </Button>
            </Hbox.Item>
          </Hbox>
          <VSeparator />
        </Grid>
      </Form>
    </Modal>
  );
};
