import React from 'react';
import { Button } from '@atomic/atm.button';
import { CheckboxField } from '@atomic/atm.checkbox';
import { FaIcon } from '@atomic/atm.fa-icon';
import { SelectField } from '@atomic/atm.select';
import { TagRadioField } from '@atomic/atm.tag-radio';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Hbox, Separator } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { VSeparator } from '@atomic/obj.grid';
import { LoadingState } from '@atomic/obj.loading-state';
import { Field } from '../form/components/form-flow-fields.container';
import {
  CheckBoxWrapper,
  FormFieldWrapper,
  FormOptionsWrapper,
  FormTagWrapper,
  FormWrapper,
} from './form-admin.component.style';
import { useCreateQuestions } from './hooks/useCreateQuestions';
import { useDeleteQuestion } from './hooks/useDeleteQuestion';
import { useEditQuestion } from './hooks/useEditQuestion';
import { useGetQuestionsByStep } from './hooks/useGetQuestionsByStep';

interface IOption {
  label: string;
  key: string;
  value: string;
  ops?: [
    {
      key: string;
      label: string;
      value: string;
    },
  ];
  conditional?: any;
}
interface SubmitData {
  data: {
    step: string;
    type: string;
    question: string;
    detail: string;
    label: string;
    key: string;
    isConditional: string;
    order: any;
    mandatory: string;
    display: string;
    permissions?: string[];
    callback?: string;
    placeholder?: string;
    unit?: any;
  };
  error: any;
  other: any;
}

export const ChatContainer = () => {
  const [questionsList, setQuestionsList] = React.useState({
    CLIENT: [],
    STEEL: [],
    PRODUCT: [],
    GOAL: [],
    ADDITIONAL: [],
  });
  const [displayField, setDisplayField] = React.useState(true);
  const [conditionFieldOptions, setConditionFieldOptions] = React.useState([]);
  const [conditionalOptionKey, setConditionalOptionKey] = React.useState(null);
  const [conditional, setConditional] = React.useState([]);
  const [fieldType, setFieldType] = React.useState(null);
  const [optionsQuestion, setOptionsQuestion] = React.useState(null);
  const [initialOption, setInitialOption] = React.useState('');
  const [optionsUnit, setOptionsUnit] = React.useState([]);
  const [initialUnit, setInitialUnit] = React.useState(null);

  const { mutate: createQuestion } = useCreateQuestions();

  const handleSubmit = async (formFields: SubmitData) => {
    if (Object.keys(formFields.error).length <= 0) {
      const options = [];
      if (optionsQuestion) {
        Object.entries(optionsQuestion).forEach((op: [string, IOption]) => {
          options.push({
            label: op[1].label,
            key: op[1].key,
            value: op[1].value,
            conditional: op[1]?.conditional && [
              {
                key: op[1].conditional.key,
                value: op[1].conditional.value,
              },
            ],
          });
        });
      }

      const unit =
        optionsUnit.length > 0
          ? {
              symbol: optionsUnit[0].symbol,
              description: optionsUnit[0].description,
              allowed: optionsUnit,
            }
          : '';

      let payload = null;

      payload = {
        label: formFields?.data?.label ? formFields.data.label : '',
        permission: {
          visible: formFields.data.permissions.includes('visible'),
          editable: formFields.data.permissions.includes('editable'),
        },
        type: formFields.data.type,
        question: formFields.data.question,
        detail: formFields?.data?.detail ? formFields.data.detail : '',
        key: formFields.data.key,
        display: formFields.data.display === 'yes' || false,
        mandatory: formFields.data.mandatory === 'yes' || false,
        step: formFields.data.step,
        readBy: 'user',
        order: parseInt(formFields.data.order),
        callback: formFields.data.callback,
        isConditional: formFields.data.isConditional === 'yes' || false,
        conditional: conditional,
        options,
      };
      if (unit) {
        payload.unit = unit;
      }

      createQuestion(payload);
    }
  };

  document.addEventListener('keydown', function(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  });

  const { mutate: getQuestionsByStep } = useGetQuestionsByStep(
    handleSuccessQuestionsByStep,
    handleErrorQuestionsByStep,
  );

  const { mutate: deleteQuestion } = useDeleteQuestion();

  const { mutate: editQuestion } = useEditQuestion();

  React.useEffect(() => {
    const steps = ['CLIENT', 'STEEL', 'PRODUCT', 'GOAL', 'ADDITIONAL'];
    steps.forEach(item => {
      //setTimeout(() => {
      getQuestionsByStep({ step: item });
      //}, index * 2000);
    });
  }, []);

  function handleSuccessQuestionsByStep(data: Field[]) {
    data.sort((a, b) => a.order - b.order);

    setQuestionsList(prevValue => ({
      ...prevValue,
      [data[0].step]: [...data],
    }));
  }
  function handleErrorQuestionsByStep(data) {
    console.log('errro', data);
  }

  const handleStepChange = step => {
    getQuestionsByStep({ step });
  };

  function removeSpecialChars(str) {
    const removedSpecialChars = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    const removedSpaces = removedSpecialChars.replace(/\s+/g, '_');
    return removedSpaces.toUpperCase();
  }

  const handleAddOption = value => {
    if (value) {
      const convertedOp = removeSpecialChars(value);

      setOptionsQuestion(prevValue => ({
        ...prevValue,
        [convertedOp]: { label: value, key: convertedOp, value: convertedOp },
      }));
    }
    setInitialOption('');
  };
  const handleConditionalOption = (key: string, opKey: string) => {
    const steps = Object.entries(questionsList).map((step: [string, Field[]]) => {
      return step[1].filter(item => item.key === key);
    });

    const ops = [];
    steps.map((question: [Field]) => {
      if (question.length > 0) {
        ops.push(...question[0].options);
      }
    });

    setOptionsQuestion(prevValue => ({
      ...prevValue,
      [opKey]: { ...prevValue[opKey], conditional: { key, ops } },
    }));
  };
  const handleConditionalOptionValue = (value: string, opKey: string) => {
    setOptionsQuestion(prevValue => ({
      ...prevValue,
      [opKey]: { ...prevValue[opKey], conditional: { ...prevValue[opKey].conditional, value: value } },
    }));
  };

  const removeOption = (key: string) => {
    setInitialOption('');
    const filtredOpstion = optionsQuestion.filter((op: { label: string; value: string; key: string }) => {
      return op.key !== key;
    });

    setOptionsQuestion(filtredOpstion);
  };

  const handleAddUnit = value => {
    if (value) {
      const unit = value.split('-');
      setOptionsUnit(prevValue => [...prevValue, { symbol: unit[0], description: unit[1] }]);
    }
    setInitialUnit('');
  };

  const removeOptionUnit = (symbol: string) => {
    setInitialUnit('');
    const filtredOpstion = optionsUnit.filter((op: { symbol: string; description: string }) => {
      return op.symbol !== symbol;
    });

    setOptionsUnit(filtredOpstion);
  };

  const handleConditional = (key: string) => {
    const steps = Object.entries(questionsList).map((step: [string, Field[]]) => {
      return step[1].filter(item => item.key === key);
    });
    setConditionalOptionKey(key);

    const options = [];
    steps.map((question: [Field]) => {
      if (question.length > 0) {
        options.push(...question[0].options);
      }
    });
    setConditionFieldOptions(options);
  };
  const handleConditionalValue = (value: string) => {
    setConditional([...conditional, { key: conditionalOptionKey, value }]);
    //setConditional({ key: conditionalOptionKey, value });
  };

  const checkPermissions = value => {
    console.log(value);
  };

  const [editedQuestion, setEditedQuestions] = React.useState(null);

  const handleSubmitEdit = async form => {
    editQuestion({
      payload: JSON.parse(form.data.editQuestion),
      key: editedQuestion.key,
      step: editedQuestion.step,
    });
  };
  function handleEdit(option) {
    setEditedQuestions({ key: option.key, step: option.step, payload: JSON.stringify(option, null, 4) });
  }

  function handleDelete(step, key) {
    deleteQuestion({ step, key });
  }

  return (
    <LoadingState loading={false} enableActivityIndicator={false}>
      <Hbox.Item>
        <Hbox>
          <FormWrapper style={{ justifyContent: 'center' }}>
            <Form id='formNewQuote' onSubmit={handleSubmit}>
              <Hbox style={{ width: '100%', flexDirection: 'column' }}>
                <FormFieldWrapper>
                  <Form.Field
                    name='step'
                    onValueChange={value => handleStepChange(value)}
                    validators={[Validators.Required()]}
                  >
                    <Body>Passo:</Body>
                    <SelectField>
                      <option value=''>Selecione</option>
                      <option value='CLIENT'>Cliente</option>
                      <option value='STEEL'>Aço</option>
                      <option value='PRODUCT'>Produto</option>
                      <option value='GOAL'>Objetivo</option>
                      <option value='ADDITIONAL'>Características adicionais</option>
                    </SelectField>
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field name='question' validators={[Validators.Required()]}>
                    <Body>Pergunta:</Body>
                    <TextField id='question' type='text'></TextField>
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field name='detail'>
                    <Body>Detalhe (texto de apoio):</Body>
                    <TextField id='detail' type='text'></TextField>
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field name='placeholder'>
                    <Body>Placeholder (texto que será exibido dentro do campo):</Body>
                    <TextField id='placeholder' type='text'></TextField>
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field name='label'>
                    <Body>Label (texto que será exibido no resumo da análise):</Body>
                    <TextField id='label' type='text'></TextField>
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field name='key' validators={[Validators.Required()]}>
                    <Body>Key:</Body>
                    <TextField id='key' type='text'></TextField>
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field
                    name='type'
                    onValueChange={value => setFieldType(value)}
                    validators={[Validators.Required()]}
                  >
                    <Body>Tipo de campo:</Body>
                    <SelectField>
                      <option value=''>Selecione</option>
                      <option value='text'>Texto</option>
                      <option value='date'>Data</option>
                      <option value='number'>Numérico</option>
                      <option value='file'>Arquivo</option>
                      <option value='radio'>Radio</option>
                      <option value='checkbox'>Checkbox</option>
                      <option value='select'>Select</option>
                      <option value='autocomplete'>Autocomplete</option>
                      <option value='range'>Range</option>
                      <option value='table'>Tabela</option>
                      <option value='chemicalComposition'>Composição química</option>
                      <option value='microInclusion'>Micro inclusão</option>
                      <option value='hardenability'>Jominy</option>
                    </SelectField>
                  </Form.Field>

                  {fieldType === 'radio' || fieldType === 'checkbox' || fieldType === 'select' ? (
                    <>
                      <Separator small />
                      <Form.Field name='ops' value={initialOption} onValueChange={value => setInitialOption(value)}>
                        <Body>Opções do tipo de campo:</Body>
                        <TextField
                          id='op'
                          onFocus={() => setInitialOption('')}
                          onBlur={value => handleAddOption(value.target.value)}
                          type='text'
                        ></TextField>
                        <Separator small />
                      </Form.Field>
                      <Form.Field name='opsList' value={initialOption}>
                        <FormOptionsWrapper>
                          {optionsQuestion &&
                            Object.entries(optionsQuestion).map(
                              (op: [string, { label: string; key: string; value: string }]) => (
                                <FormTagWrapper key={`option-${op[0]}`}>
                                  <TagRadioField
                                    onClick={() => removeOption(op[0])}
                                    id={`option-${op[0]}`}
                                    type='success'
                                    disabled={false}
                                  >
                                    <span>
                                      {op[1].label} <FaIcon.Close size='1x' />
                                    </span>
                                  </TagRadioField>
                                  <Separator small />
                                  <Body>Condição para a opção aparecer:</Body>
                                  <SelectField onValueChange={value => handleConditionalOption(value, op[0])}>
                                    <option value=''>Selecione</option>
                                    {Object.entries(questionsList).map((step: [string, Field[]]) => (
                                      <optgroup label={step[0]} key={step[0]}>
                                        {step[1].map(
                                          (question: Field) =>
                                            question?.options?.length > 0 && (
                                              <option
                                                key={question.key}
                                                value={question.key}
                                              >{`${question.question} [${question.key}]`}</option>
                                            ),
                                        )}
                                      </optgroup>
                                    ))}
                                  </SelectField>
                                  {optionsQuestion && optionsQuestion[op[0]]?.conditional && (
                                    <>
                                      <Separator small />
                                      <SelectField onValueChange={value => handleConditionalOptionValue(value, op[0])}>
                                        <option value=''>Selecione</option>
                                        {optionsQuestion[op[0]]?.conditional?.ops.map((op2: IOption) => (
                                          <option key={op2.key} value={op2.key}>
                                            {op2.label}
                                          </option>
                                        ))}
                                      </SelectField>
                                      <Button>Adicionar</Button>
                                    </>
                                  )}
                                  <VSeparator />
                                </FormTagWrapper>
                              ),
                            )}
                        </FormOptionsWrapper>
                      </Form.Field>
                    </>
                  ) : null}
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field name='unitOps' initialValue={initialUnit} onValueChange={value => setInitialUnit(value)}>
                    <Body>Unidades:</Body>
                    <TextField
                      id='un'
                      onFocus={() => setInitialUnit('')}
                      onBlur={value => handleAddUnit(value.target.value)}
                      type='text'
                    ></TextField>
                    <Separator small />
                  </Form.Field>

                  <Form.Field name='unitOpsList' initialValue={optionsUnit[0]}>
                    {optionsUnit.length > 0 &&
                      optionsUnit.map((op: { symbol: string; description: string }) => (
                        <>
                          <TagRadioField
                            onClick={() => removeOptionUnit(op.symbol)}
                            id={`option-${op.symbol}`}
                            type='success'
                            disabled={false}
                          >
                            <span>
                              {`${op.symbol}-${op.description}`} <FaIcon.Close size='1x' />
                            </span>
                          </TagRadioField>
                          <VSeparator />
                        </>
                      ))}
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field name='isConditional' validators={[Validators.Required()]}>
                    <Body>Campo condicional:</Body>
                    <SelectField>
                      <option value=''>Selecione</option>
                      <option value='yes'>Sim</option>
                      <option value='no'>Não</option>
                    </SelectField>
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field name='order' validators={[Validators.Required()]}>
                    <Body>Ordem:</Body>
                    <TextField id='order' noArrows={true} type='number'></TextField>
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field name='callback'>
                    <Body>Callback URL:</Body>
                    <TextField id='callback' type='text'></TextField>
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field name='mandatory' validators={[Validators.Required()]}>
                    <Body>Obrigatório:</Body>
                    <SelectField>
                      <option value=''>Selecione</option>
                      <option value='yes'>Sim</option>
                      <option value='no'>Não</option>
                    </SelectField>
                  </Form.Field>
                </FormFieldWrapper>
                <Separator />

                <FormFieldWrapper>
                  <Form.Field
                    name='display'
                    onValueChange={value => setDisplayField(value === 'yes' || false)}
                    validators={[Validators.Required()]}
                  >
                    <Body>Exibir pergunta:</Body>
                    <SelectField>
                      <option value=''>Selecione</option>
                      <option value='yes'>Sim</option>
                      <option value='no'>Não</option>
                    </SelectField>
                  </Form.Field>
                </FormFieldWrapper>
                {!displayField && (
                  <>
                    <Separator small />
                    <Body>Condição para a opção aparecer:</Body>
                    <SelectField onValueChange={value => handleConditional(value)}>
                      <option value=''>Selecione</option>
                      {Object.entries(questionsList).map((step: [string, Field[]]) => (
                        <optgroup label={step[0]} key={step[0]}>
                          {step[1].map(
                            (question: Field) =>
                              question?.options?.length > 0 && (
                                <option
                                  key={question.key}
                                  value={question.key}
                                >{`${question.question} [${question.key}]`}</option>
                              ),
                          )}
                        </optgroup>
                      ))}
                    </SelectField>
                    {conditionFieldOptions.length > 0 && (
                      <>
                        <Separator small />
                        <SelectField onValueChange={value => handleConditionalValue(value)}>
                          <option value=''>Selecione</option>
                          {conditionFieldOptions.map((op: Field) => (
                            <option key={op.key} value={op.key}>
                              {op.label}
                            </option>
                          ))}
                        </SelectField>
                      </>
                    )}
                  </>
                )}
                <Separator />

                <FormFieldWrapper>
                  <Form.Field
                    name='permissions'
                    onValueChange={value => checkPermissions(value)}
                    validators={[Validators.Required()]}
                  >
                    <Body>Permissões:</Body>
                    <Separator small />
                    <CheckBoxWrapper>
                      <CheckboxField id='visible'>
                        <span>Visível no resumo da análise</span>
                      </CheckboxField>
                      <VSeparator />
                      <CheckboxField id='editable'>
                        <span>Editável</span>
                      </CheckboxField>
                    </CheckBoxWrapper>
                    <Separator />
                  </Form.Field>
                </FormFieldWrapper>

                <Button expanded kind='callToAction' type='submit'>
                  Enviar
                </Button>
              </Hbox>
              <Hbox.Separator small />
              <Separator />
            </Form>
          </FormWrapper>
          <Hbox.Item>
            <FormWrapper style={{ justifyContent: 'center' }}>
              <Form id='formNewQuote' onSubmit={handleSubmitEdit}>
                <Hbox style={{ width: '100%', flexDirection: 'column' }}>
                  <FormFieldWrapper>
                    <Form.Field name='editQuestion' initialValue={editedQuestion?.payload}>
                      <TextAreaField style={{ height: '700px', overflow: 'auto' }}></TextAreaField>
                    </Form.Field>
                  </FormFieldWrapper>
                  <Button expanded kind='callToAction' type='submit'>
                    Enviar
                  </Button>
                </Hbox>
              </Form>
            </FormWrapper>
          </Hbox.Item>
        </Hbox>
        <Hbox.Item>
          <Hbox>
            <FormWrapper style={{ justifyContent: 'center' }}>
              {Object.entries(questionsList).map((step: [string, Field[]]) => (
                <>
                  {step[1].map((question: Field) => (
                    <>
                      <Hbox key={question.key}>
                        <Body>{`${question.order} - [${question.key}] - ${question.question}`}</Body>
                        <Button kind='link' size='small' onClick={() => handleEdit(question)}>
                          Editar
                        </Button>{' '}
                        <Button kind='link' size='small' onClick={() => handleDelete(question.step, question.key)}>
                          Excluir
                        </Button>
                      </Hbox>
                      <Separator small />
                    </>
                  ))}
                  <Separator />
                  <Separator />
                  <Separator />
                  <Separator />
                </>
              ))}
            </FormWrapper>
          </Hbox>
        </Hbox.Item>
      </Hbox.Item>
    </LoadingState>
  );
};

export const ChatPage = () => {
  return <ChatContainer />;
};
