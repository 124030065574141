/* eslint-disable complexity */

import React from 'react';
import { SelectField } from '@atomic/atm.select';
import { TextField } from '@atomic/atm.text-field';
import { Body } from '@atomic/atm.typography';
import { Form, Validators } from '@atomic/obj.form';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';
import { FormTextFieldWrapper, FormUnitWrapper } from '../result/form-result.component.style';

export const TextDefaultField = (props: FormFlowProps) => {
  const { formResult, strings } = React.useContext(FormContext);
  const [unit, setUnit] = React.useState(
    props.field?.unit?.allowed?.length > 1 ? props.field?.unit?.allowed[0]?.symbol : props.field?.unit?.symbol,
  );

  const stringsNewForm = strings.newForm.messages;

  const getValues = value => {
    props.handleValue(value, 'text', props.field.key, false, props.stepKey, unit);
  };

  return (
    <Form.Field
      name={props.field.key}
      validators={props?.field?.mandatory ? [Validators.Required(stringsNewForm.mandatoryAlert)] : []}
      initialValue={
        formResult !== null && formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value
          ? formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value
          : null
      }
    >
      <FormTextFieldWrapper>
        <TextField
          id={props.field.key}
          type={props.field.type}
          noArrows={props.field.type === 'number'}
          onValueChange={value => getValues(value)}
        ></TextField>

        {props.field?.unit?.allowed?.length > 1 ? (
          <FormUnitWrapper>
            <Form.Field
              name={props.field.key}
              onValueChange={value => setUnit(value)}
              validators={[Validators.Required(stringsNewForm.mandatoryAlert)]}
            >
              <SelectField id={`${props.field.key}-unit`}>
                {props.field?.unit.allowed.map((el: { symbol: string; description: string }) => (
                  <option value={el.symbol} key={el.symbol}>
                    {el.description}
                  </option>
                ))}
              </SelectField>
            </Form.Field>
          </FormUnitWrapper>
        ) : (
          <FormUnitWrapper>
            <Body>{formResult[props.stepKey]?.questions?.fields[props.field?.key]?.unit.allowed[0].symbol}</Body>
          </FormUnitWrapper>
        )}
      </FormTextFieldWrapper>
    </Form.Field>
  );
};
