import React from 'react';
import { TagRadioField } from '@atomic/atm.tag-radio';
import { TextField } from '@atomic/atm.text-field';
import { Form, Validators } from '@atomic/obj.form';
import { LoadingState } from '@atomic/obj.loading-state';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';
import { FormResultItems } from '../result/form-result.component';
import { CustomFieldWrapper } from '../result/form-result.component.style';
export interface Options {
  label: string;
  key: string;
  value: string;
  conditional?: [
    {
      key: string;
      value: string;
    },
  ];
}

export const RadioField = (props: FormFlowProps) => {
  const [otherValue, setOtherValue] = React.useState(false);
  const { formResult, strings } = React.useContext(FormContext);
  const [filtredOptions, setFiltredOptions] = React.useState([]);
  const stringsNewForm = strings.newForm.messages;

  function checkOtherValue(value: { value: string; key: string; label: string; conditional: any }) {
    if (value?.value === 'OTHER') {
      setOtherValue(true);
    } else if (value === null) {
      setOtherValue(false);
    } else {
      setOtherValue(false);
      props.handleValue(value, 'radio', props.field.key, props.field.isConditional, props.stepKey);
    }
  }

  const checkOptions = (options?: any) => {
    const ops = [];

    options.forEach((op: Options) => {
      if (op?.conditional && op?.conditional?.length > 0) {
        op.conditional.forEach(cond => {
          if (formResult[props.stepKey]?.questions?.fields[cond.key]?.value?.key === cond.value) {
            ops.push(op);
          }
        });
      } else {
        ops.push(op);
      }
    });

    const conjunto = new Set(ops);
    const finalOps = Array.from(conjunto);

    setFiltredOptions(finalOps);
  };

  const checkValidator = (value: FormResultItems[], mandatory: boolean) => {
    if (mandatory) {
      if (value) {
        return [];
      }
      return [Validators.Required(stringsNewForm.mandatoryAlert)];
    } else {
      return [];
    }
  };

  React.useEffect(() => {
    checkOptions(props.field.options);
  }, [formResult]);

  return filtredOptions.length > 0 ? (
    !otherValue ? (
      <LoadingState loading={props?.field?.loading}>
        <Form.Field
          name={props.field.key}
          onValueChange={value => checkOtherValue(value)}
          validators={checkValidator(props.field?.value, props.field?.mandatory)}
          initialValue={formResult[props.stepKey]?.questions?.fields[props.field.key]?.value?.key}
        >
          {filtredOptions.map(option => (
            <TagRadioField key={option.key} id={option.key} type='success' disabled={false}>
              <span data-testid={`option${option.key}`}>{option.label}</span>
            </TagRadioField>
          ))}
        </Form.Field>
      </LoadingState>
    ) : (
      <Form.Field
        name={props.field.key}
        validators={props?.field?.mandatory ? [Validators.Required(stringsNewForm.mandatoryAlert)] : []}
        initialValue={
          formResult !== null &&
          typeof formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value === 'string'
            ? formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value
            : null
        }
      >
        <CustomFieldWrapper>
          <TextField
            id={props.field.key}
            onBlur={value => props.handleValue(value.target.value, 'text', props.field.key, false, props.stepKey)}
          ></TextField>
          <TagRadioField onClick={() => checkOtherValue(null)} id='option-other' type='success' disabled={false}>
            <span data-testid={`option${props.field.key}-other`}>{stringsNewForm.btnBackOption}</span>
          </TagRadioField>
        </CustomFieldWrapper>
      </Form.Field>
    )
  ) : null;
};
