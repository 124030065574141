import React from 'react';
import { CheckboxField } from '@atomic/atm.checkbox';
import { TagRadioField } from '@atomic/atm.tag-radio';
import { TextField } from '@atomic/atm.text-field';
import { Form, Validators } from '@atomic/obj.form';
import { FormContext } from '../../form.context';
import { FormFlowProps } from '../form-flow-fields.container';
import { FormResultItems } from '../result/form-result.component';
import { CheckBoxWrapper, CustomFieldWrapper } from '../result/form-result.component.style';

export const CheckBoxField = (props: FormFlowProps) => {
  const [otherValue, setOtherValue] = React.useState(false);
  const { formResult, strings } = React.useContext(FormContext);
  const stringsNewForm = strings.newForm.messages;

  function checkOtherValue(value: string[]) {
    if (value) {
      if (value.includes('OTHER')) {
        setOtherValue(true);
      } else if (value === null) {
        setOtherValue(false);
      } else {
        setOtherValue(false);
        props.handleValue(value, 'checkbox', props.field.key, props.field.isConditional, props.stepKey);
      }
    } else {
      setOtherValue(false);
    }
  }

  const checkValidator = (value: FormResultItems[], mandatory: boolean) => {
    if (mandatory) {
      if (value) {
        return [];
      }
      return [Validators.Required(stringsNewForm.mandatoryAlert)];
    } else {
      return [];
    }
  };

  return !otherValue ? (
    <Form.Field
      name={props.field.key}
      onValueChange={value => checkOtherValue(value)}
      validators={checkValidator(props.field?.value, props.field?.mandatory)}
      initialValue={formResult && formResult[props.stepKey]?.questions?.fields[props.field.key]?.value}
    >
      <CheckBoxWrapper>
        {props.field?.options.map(option => (
          <CheckboxField data-testid={option.key} key={option.key} id={option.key}>
            <span data-testid={`option${option.key}`}>{option.label}</span>
          </CheckboxField>
        ))}
      </CheckBoxWrapper>
    </Form.Field>
  ) : (
    <Form.Field
      name={props.field.key}
      validators={props?.field?.mandatory ? [Validators.Required(stringsNewForm.mandatoryAlert)] : []}
      initialValue={
        formResult !== null && typeof formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value === 'string'
          ? formResult[props.stepKey]?.questions?.fields[props.field?.key]?.value
          : null
      }
    >
      <CustomFieldWrapper>
        <TextField
          id={props.field.key}
          onBlur={value => props.handleValue([value.target.value], 'checkbox', props.field.key, false, props.stepKey)}
        ></TextField>
        <TagRadioField onClick={() => checkOtherValue(null)} id='option-other' type='success' disabled={false}>
          <span data-testid={`option${props.field.key}-other`}>{stringsNewForm.btnBackOption}</span>
        </TagRadioField>
      </CustomFieldWrapper>
    </Form.Field>
  );
};
