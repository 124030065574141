/* eslint-disable prettier/prettier */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { InputValue } from '@atomic/atm.typography';
import { Color, Spacing, ZIndex } from '@atomic/obj.constants';
import { highlightStyle } from '@atomic/obj.mixin';

export const checkboxHeight = 20;
export const marginBetweenCheckboxItems = Spacing.XSmall;

export interface CheckboxProps {
  disabled?: boolean;
  paddingLeft?: string;
}

export enum CheckboxStyle {
  Primarty = 'primary',
  Secondary = 'secondary',
}

export const CheckboxFieldStyled = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
  flex-direction: row;
  align-items: center;

  & ~ * {
    margin-top: ${marginBetweenCheckboxItems};
  }
`;

export const CheckboxFieldTextStyled = styled(InputValue)`
  padding-left: ${(props: CheckboxProps) => (props.paddingLeft ? props.paddingLeft : Spacing.XLarge)};
  line-height: ${checkboxHeight}px;
  color: ${(props: CheckboxProps) => (props.disabled ? Color.Gray : Color.Black)};
  z-index: ${ZIndex.CheckboxFieldTextStyled};
  ${highlightStyle};
`;

export const CheckboxCheckedStyledSecondary = styled(FontAwesomeIcon).attrs({ icon: 'check' })`
  position: absolute;
  font-size: ${checkboxHeight}px !important;
  border: 2px solid ${Color.Black};
  border-radius: 2px;
  opacity: 0;
`;

export const CheckboxCheckedStyledPrimary = styled(FontAwesomeIcon).attrs({ icon: 'check-square' })`
  position: absolute;
  font-size: ${checkboxHeight}px !important;
  color: ${(props: CheckboxProps) => (props.disabled ? Color.Secondary : Color.Primary)};
  z-index: ${ZIndex.CheckboxCheckedStyled};
  opacity: 0;
`;

export const CheckboxUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: ['far', 'square'] })`
  position: absolute;
  font-size: ${checkboxHeight}px !important;
  color: ${(props: CheckboxProps) => (props.disabled ? Color.Gray : Color.Black)};
  z-index: ${ZIndex.CheckboxUncheckedStyled};
  opacity: 1;
`;

export const CheckboxFieldBulletStyled = styled.input`
  display: none;

  &:checked
    + ${props =>
    props.checkboxStyle === CheckboxStyle.Secondary ? CheckboxCheckedStyledSecondary : CheckboxCheckedStyledPrimary} {
    opacity: 1;
    + ${CheckboxUncheckedStyled} {
      opacity: 0;
    }
  }
`;
