import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@app/core/redux/store';
import { Button } from '@atomic/atm.button';
import { TextAreaField, TextField } from '@atomic/atm.text-field';
import { Body, H2 } from '@atomic/atm.typography';
//import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { DefaultListCell } from '@atomic/mol.default-list-cell';
import { Tab } from '@atomic/mol.tab';
import { Hbox } from '@atomic/obj.box';
import { Form, Validators } from '@atomic/obj.form';
import { Col, Grid, Row, VSeparator } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
//import { Visibility } from '@atomic/obj.visibility';
import { Visibility } from '@atomic/obj.visibility';
import { FormContext } from '../form.context';
import { FormStrings } from '../form.string';
import {
  AttributesContentWrapperStyled,
  AttributesTabsWrapperStyled,
} from './modal-aditional-attributes.component.style';

export interface Attributes {
  key: string;
  label: string;
  data: string[];
  ButtonsList_PropriedadesMecanicas: string[];
  ButtonsList_PropriedadesMetalurgicas: string[];
  ButtonsList_Dimensional: string[];
  ButtonsList_OutrasCaracteristicas: string[];
  ButtonsList_TiposdeEnsaio: string[];
  ButtonsList_Produto: string[];
}

interface ModalAttributeProps {
  open: boolean;
  submitModals: (stepKey: string, key: string, values: any) => void;
}

export const ModalAditionalAttributes: React.FC<ModalAttributeProps> = props => {
  const { setModalToOpen, formResult, setFormResult, scrollTo, openModals } = React.useContext(FormContext);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [opened, setOpened] = React.useState(props.open);
  const [selectedAttribute, setSelectedAttribute] = React.useState(null);
  const [newAttributeForm, setNewAttributeForm] = React.useState(false);

  const additionalCategories = [
    {
      key: 'DIMENTIONAL',
      label: 'Dimensional',
      options: [
        'DIAGONAL_DIFFERENCE',
        'BENDING',
        'CROOKED',
        'LENGTH_MULTIPLES',
        'NUMERIC_CORNER_RADIUS',
        'REDUCTION_RATE',
        'TOLERANCE',
        'TORSION',
      ],
    },
    {
      key: 'MECHANICAL_PROPERTIES',
      label: 'Propriedades mecânicas',
      options: [
        'HARDNESS_HALF_RADIUS',
        'HARDNESS_THIRD_RADIUS',
        'HARDNESS_NUCLEUS',
        'HARDNESS_SUPERFICIAL',
        'LOAD_LIMIT',
        'YIELD_LIMIT',
        'AREA_SHRINK',
        'STRETCH',
        'TRACTION',
      ],
    },
    {
      key: 'METALLURGICAL_PROPERTIES',
      label: 'Propriedades metalúrgicas',
      options: [
        'HARDENABILITY',
        'MICRO_INCLUSION',
        'CHEMICAL_COMPOSITION',
        'MACRO_ETCH',
        'IDEAL_CRITICAL_DIAMETER',
        'SPHEROIDIZING_DEGREE',
        'MICROSTRUCTURE',
        'PARTIAL_DECARBURIZING',
        'TOTAL_DECARBURIZING',
        'GLOBAL_DECARBURIZING',
      ],
    },
  ];

  const { userInfo } = useSelector((state: RootState) => state.auth);
  const strings = FormStrings[userInfo.language];

  // const { data: aditionalAttributes } = useGetAdditionalAttributesList(
  //   {
  //     flow: 'ADDITIONAL',
  //     language: userInfo.language,
  //   },
  //   handleSuccesAdditionalList,
  // );

  // function handleSuccesAdditionalList(response) {
  //   console.log('cccccccccc', response);
  // }

  const handleTabChange = (index: number) => {
    setTabIndex(index);
  };

  const handleSubmit = data => {
    // const attributeLabel = data.data?.label ? data.data?.label : data.data?.attribute;
    // const attributeValue = data.data?.value ? data.data?.value : data.data?.attribute;
    if (Object.keys(data.error).length > 0) {
      return;
    }
    //props.onSubmit(attributeLabel, attributeValue, newAttributeForm);
    setSelectedAttribute(null);
    setNewAttributeForm(false);
    setOpened(false);
  };

  const handleOption = (item: any) => {
    if (item === 'TRACTION') {
      setFormResult(prevValue => ({
        ...prevValue,
        ADDITIONAL: {
          ...prevValue.ADDITIONAL,
          questions: {
            ...prevValue.ADDITIONAL.questions,
            fields: {
              ...prevValue.ADDITIONAL.questions.fields,
              LOAD_LIMIT: {
                ...prevValue.ADDITIONAL.questions.fields.LOAD_LIMIT,
                display: true,
              },
              YIELD_LIMIT: {
                ...prevValue.ADDITIONAL.questions.fields.YIELD_LIMIT,
                display: true,
              },
              AREA_SHRINK: {
                ...prevValue.ADDITIONAL.questions.fields.AREA_SHRINK,
                display: true,
              },
              STRETCH: {
                ...prevValue.ADDITIONAL.questions.fields.STRETCH,
                display: true,
              },
            },
          },
        },
      }));
    } else {
      setFormResult(prevValue => ({
        ...prevValue,
        ADDITIONAL: {
          ...prevValue.ADDITIONAL,
          questions: {
            ...prevValue.ADDITIONAL.questions,
            fields: {
              ...prevValue.ADDITIONAL.questions.fields,
              [item]: {
                ...prevValue.ADDITIONAL.questions.fields[item],
                display: true,
              },
            },
          },
        },
      }));
    }

    setModalToOpen(null);
    setOpened(false);

    scrollTo(item, 'ADDITIONAL');
    if (
      item === 'HARDENABILITY' ||
      item === 'MICRO_INCLUSION' ||
      item === 'CHEMICAL_COMPOSITION' ||
      item === 'MACRO_ETCH'
    ) {
      openModals(item);
    }
  };

  const handleClose = () => {
    setOpened(false);
    setModalToOpen(null);
  };

  return (
    <Modal preventOverlayClick medium opened={opened} onClose={handleClose}>
      <Form onSubmit={handleSubmit}>
        {!newAttributeForm ? (
          <Grid fluid>
            <H2>{strings.modalAttributes.title}</H2>
            <AttributesTabsWrapperStyled>
              <Tab initialIndex={tabIndex} onIndexChanged={handleTabChange}>
                {additionalCategories?.map((tab: { key: string; label: string; options: string[] }) => {
                  if (tab.options.length > 0) {
                    return (
                      <Tab.Item key={tab.key}>
                        <Hbox>
                          <Hbox.Item>{tab.label}</Hbox.Item>
                        </Hbox>
                      </Tab.Item>
                    );
                  } else {
                    return '';
                  }
                })}
              </Tab>
            </AttributesTabsWrapperStyled>
            <Row>
              <Col xs={12}>
                <Form.Field
                  name='attribute'
                  initialValue={selectedAttribute}
                  validators={[Validators.Required(strings.modalAttributes.alertMandatory)]}
                >
                  {additionalCategories?.map((list: { key: string; label: string; options: string[] }, index) => {
                    if (list.options.length > 0) {
                      return (
                        <Visibility key={index} visible={tabIndex === index}>
                          <AttributesContentWrapperStyled>
                            {list.options.map(
                              (item, index2) =>
                                formResult?.ADDITIONAL?.questions?.fields[item] && (
                                  <DefaultListCell
                                    selected={selectedAttribute === item}
                                    key={index2}
                                    onClick={() => handleOption(item)}
                                  >
                                    <Body>{formResult?.ADDITIONAL?.questions?.fields[item].label}</Body>
                                  </DefaultListCell>
                                ),
                            )}
                          </AttributesContentWrapperStyled>
                        </Visibility>
                      );
                    } else {
                      return '';
                    }
                  })}
                </Form.Field>
              </Col>
            </Row>
            <Hbox>
              {tabIndex === additionalCategories.length - 1 && (
                <Hbox.Item hAlign='flex-start'>
                  <Button kind='link' onClick={() => setNewAttributeForm(true)}>
                    {strings.modalAttributes.btnAdd}
                  </Button>
                </Hbox.Item>
              )}
            </Hbox>
            <VSeparator />
          </Grid>
        ) : (
          <Grid fluid>
            <H2>{strings.modalAttributes.titleAdd}</H2>
            <Row>
              <Col xs={12}>
                <Body>{strings.modalAttributes.fieldTitle.label}</Body>
                <VSeparator small />
                <Form.Field
                  name='label'
                  validators={[
                    Validators.Required(strings.modalAttributes.alertMandatory2),
                    Validators.SpecialChars(strings.modalAttributes.alertSpecialChars),
                  ]}
                >
                  <TextField placeholder={strings.modalAttributes.fieldTitle.placeholder} />
                </Form.Field>
                <VSeparator />
              </Col>

              <Col xs={12}>
                <Body>{strings.modalAttributes.fieldValue.label}</Body>
                <VSeparator small />
                <Form.Field name='value' validators={[Validators.Required(strings.modalAttributes.alertMandatory2)]}>
                  <TextAreaField placeholder={strings.modalAttributes.fieldValue.placeholder} disableResize />
                </Form.Field>
                <VSeparator />
              </Col>
            </Row>
            <Hbox hAlign='flex-end'>
              <Hbox.Item noGrow>
                <Button kind='secondary' expanded onClick={() => setNewAttributeForm(false)}>
                  {strings.modalAttributes.btnBack}
                </Button>
              </Hbox.Item>
              <Hbox.Separator />
              <Hbox.Item noGrow>
                <Button kind='primary' expanded type='submit'>
                  {strings.modalAttributes.btnAddSubmit}
                </Button>
              </Hbox.Item>
            </Hbox>
            <VSeparator />
          </Grid>
        )}
      </Form>
    </Modal>
  );
};
