import { Field, Steps } from '../components/form-flow-fields.container';

export const mapFields = res => {
  const orderedFields = Object.values(res).sort((a: Field, b: Field) => a.order - b.order);

  return orderedFields.reduce((acc, item: Field) => {
    acc[item.key] = { ...item, loading: false };
    return acc;
  }, {});
};

export const mapFormFlow = (response: Steps, flow: string) => {
  return {
    [flow]: {
      key: flow,
      questions: {
        key: response.key,
        label: response.label,
        question: response.question,
        fields: mapFields(response.fields),
      },
    },
  };
};

export const mapSteelFlow = response => {
  const steelList = response.map(item => {
    return { key: item, label: item, value: item };
  });
  steelList.push({ key: 'OTHER', label: 'Outro', value: 'OTHER' });

  return steelList;
};

export const mapAdditionalAttributesList = response => {
  const entries = Object.entries(response.ADDITIONAL.questions.fields);

  const fields = entries.map((field: [string, Field]) => {
    return { key: field[1].key, label: field[1].label };
  });

  return fields;
};
